export const generateCompactramImage = (doors, decks, height) => {
  switch (decks) {
    case "3":
      if (doors === "1") {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055277/Configurador-Compactram/Finais/Compactram_2021_3c%E3%83%8Earas_90_cn_ilu5uw.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055264/Configurador-Compactram/Finais/Compactram_2021_3c%E3%83%8Earas_90__cn_ca_opqb0f.svg"
        }
      } else {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055358/Configurador-Compactram/Finais/Compactram_2021_3c%E3%83%8Earas_45_cn_tkfdqq.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055343/Configurador-Compactram/Finais/Compactram_2021_3c%E3%83%8Earas_45_cn_ca_wvmylp.svg"
        }
      }

    case "4":
      if (doors === "1") {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055423/Configurador-Compactram/Finais/Compactram_2021_4c%E3%83%8Earas_90_cn_m4mjwe.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055410/Configurador-Compactram/Finais/Compactram_2021_4c%E3%83%8Earas_90_cn_ca_byruub.svg"
        }
      } else {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055469/Configurador-Compactram/Finais/Compactram_2021_4c%E3%83%8Earas_45_cn_kviscv.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055456/Configurador-Compactram/Finais/Compactram_2021_4c%E3%83%8Earas_45_cn_ca_w5aszt.svg"
        }
      }

    case "5":
      if (doors === "1") {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055506/Configurador-Compactram/Finais/Compactram_2021_5c%E3%83%8Earas_90cn_vcuqkn.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055488/Configurador-Compactram/Finais/Compactram_2021_5c%E3%83%8Earas_90__cn_ca_fmypcb.svg"
        }
      } else {
        if (height === "22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055548/Configurador-Compactram/Finais/Compactram_2021_5c%E3%83%8Earas_45_cn_spflc1.svg"
        } else {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1623055536/Configurador-Compactram/Finais/Compactram_2021_5c%E3%83%8Earas_45_cn_ca_pn9nhh.svg"
        }
      }
  }
}
