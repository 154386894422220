import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import { generateCompactramImage } from "../components/Configuradores/imageUtils/compactram"

// Components
import SEO from "../components/SEO"
import { Configurador } from "../components/Configuradores/Configurador"
import Compactram from "../components/Configuradores/inputs/compactram.json"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ConfigCompactram = ({ location }) => {
  const { t } = useTranslation()

  const configuradorForm = t("configurador", { returnObjects: true })
  const userForm = t("form", { returnObjects: true })

  const [finalImg, setFinalImg] = useState("")

  const handleFinalImg = inputs => {
    let doors = inputs["numero-portas-por-camaras"],
      decks = inputs["numero-camaras"],
      height = inputs["altura-camara"]

    setFinalImg(generateCompactramImage(doors, decks, height))
  }

  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Compactram" bodyClass={"configurador"}
        domain={location?.hostname}></SEO>
      <Configurador
        inputsJson={Compactram}
        configuradorForm={configuradorForm}
        userForm={userForm}
        email={configuradorForm["email"]}
        oven={"Compactram"}
        finalImg={finalImg}
        handleFinalImg={handleFinalImg}
      />
    </Layout>
  )
}

export default ConfigCompactram

export const ConfiguradorCompactram = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["compactram", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
